import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { graphql, useStaticQuery, Link } from 'gatsby';
import SEO from '../../components/SEO';

const Graph = () => {
    const QUERY = graphql`
    {
        api {
            latestImages: images(sort: "date_added", limit: 12) {
                _key
                dateAdded
                description
                file
                score
                tags {
                    name
                    slug
                    _key
                }
            }
            popularImages: images(sort: "score", limit: 12) {
                _key
                dateAdded
                description
                file
                score
                tags {
                    name
                    slug
                    _key
                }
            }
            allImageTags {
              _key
              name
              slug
              file
            }
        }
    }`

    const data = useStaticQuery(QUERY);
    const {allImageTags, latestImages, popularImages} = data.api;

    return (
        <>
            <SEO  title='graphTest' />
                <Row>
                    <Col xs={12}>
                        <h2 style={{backgroundColor: '#fff', textTransform: 'uppercase', textAlign: 'center', padding: '1.5em', marginBottom: '1.5em', fontSize: '1em'}}>The Best of the Best Coffee Memes</h2>
                    </Col>
                    {
                            popularImages.map((i, k) => {
                                return (
                                    <Col key={k} sm={6} md={4} lg={3}>
                                        <Card onClick={() => {window.location.href = `/coffee-memes/m/${i._key}`}} style={{height: '180px', marginBottom: '2em', textAlign: 'center'}}>
                                        <Link to={`/coffee-memes/m/${i._key}`} style={{display: 'inline-block', maxHeight: '100%', maxWidth: '100%'}}>
                                            <img
                                                height={160} 
                                                width={160} 
                                                src={i.file} 
                                                alt={i.description} 
                                                style={{maxWidth: '100%', maxHeight: '100%'}} />
                                        </Link>
                                        </Card>
                                    </Col>
                                )
                            })
                    }
                </Row>
                <Row style={{marginTop: '5em'}}>
                    <Col xs={12}>
                        <h2>Our Latest & Greatest Coffee Memes & Quotes</h2>
                    </Col>
                        {
                            latestImages.map((i, k) => {
                                return (
                                    <Col key={k} sm={6} md={4} lg={3}>
                                        <Card onClick={() => {window.location.href = `/coffee-memes/m/${i._key}`}} style={{height: '180px', marginBottom: '2em', textAlign: 'center'}}>
                                        <Link to={`/coffee-memes/m/${i._key}`} style={{display: 'inline-block', maxHeight: '100%', maxWidth: '100%'}}>
                                            <img
                                                height={160}
                                                width={160} 
                                                style={{maxWidth: '100%', maxHeight: '100%'}} 
                                                src={i.file} 
                                                alt={i.description} />
                                            </Link>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                </Row>
                <div style={{margin: '4em 0 6em'}}>
                </div>
                <Row>
                    <Col xs={12}>
                        <h2>All Coffee Meme & Quote Categories</h2>
                    </Col>
                    {
                        allImageTags.map((t, k) => {
                            return (
                                <Col key={k} sm={6} md={4} lg={3}>
                                    <Card onClick={() => {window.location.href = `/coffee-memes/t/${t.slug}`}} style={{height: '180px',border: 'none', marginBottom: '2em', backgroundImage: `url('${(t.file, 160, 250)}')`, backgroundSize: 'cover'}}>
                                        <div>
                                            <h4 style={{
                                                backgroundColor: '#fff', 
                                                marginTop: '70px', 
                                                color: '#d37',
                                                textTransform: 'uppercase',
                                                fontSize: '.85em',
                                                fontWeight: 600,
                                                padding: '.75em 0'
                                                }}>
                                                    <a style={{color: '#d37'}} href={`/coffee-memes/t/${t.slug}`} title={`${t.name} Coffee Memes and Quotes`}>{t.name}</a>
                                                </h4>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
        </>
    )
}
export default Graph;

